import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../layout/Layout';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { roleMapping } from '../../../helpers/transformations';
import NewUser from './NewUser';
import { logoutUser } from '../../../redux/slices/userSlice';
import VenueSelect from '../Navigation/VenueSelect';

const Account = () => {
  const dispatch = useDispatch();
  const { user, selectedVenue } = useSelector((state) => state.user);
  const roles = user.RoleIds;
  const navigate = useNavigate();

  let roleLabels = [];
  if (roles) {
    roleLabels = roles.map((roleId) => roleMapping[roleId]);
  }

  const handleLogout = () => {
    dispatch(logoutUser());
  }

  const handleChangePassword = () => {
    navigate('/reset-password');
  }

  return (
    <Layout>

      <Card>
        <Card.Body className="account">
          <div className="row g-0 justify-content-center">
            <Card className="m-2 col-lg-5">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="d-flex justify-content-between align-items-center">

                  Login Info

                </Card.Title>
              </Card.Header>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    {user.Email}
                  </div>
                  <div className="my-2">
                    <Button
                      className="w-100"
                      onClick={() => {
                        handleChangePassword();
                      }}
                    >
                      Change password
                    </Button>
                  </div>
                  <div className="my-2">
                    <Button
                      className="w-100"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Log out
                    </Button>
                  </div>
                </div>


              </Card.Body>
            </Card>
            {selectedVenue &&
              <Card className="m-2 col-lg-5">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Card.Title>
                    Venue
                  </Card.Title>

                </Card.Header>
                <Card.Body>
                  <VenueSelect />
                  <div className="text-muted">
                    <div>{selectedVenue.Address1}</div>
                    <div>{selectedVenue.City}, {selectedVenue.State}</div>
                  </div>
                </Card.Body>
              </Card>
            }
            <Card className="m-2 col-lg-5">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Your Access</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="text-muted lh-1" />
                <div className="row pb-2">
                  {roleLabels.map((role) => {
                    return <h4
                      className="text-secondary"
                      key={role}
                           >{role}</h4>;
                  })}
                </div>
              </Card.Body>
            </Card>

            {roles && roles.includes(10) &&
              <Card className="m-2 col-lg-5">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Card.Title>Add User</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="text-muted lh-1" />
                  <div className="row pb-2">
                    <NewUser />
                  </div>
                </Card.Body>
              </Card>
            }
          </div>
        </Card.Body>
      </Card >
    </Layout >
  );
};

export default Account;
