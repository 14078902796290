import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './scrolling-text.scss';
import Layout from '../../../../layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { handleScroll } from '../../../../helpers/scrollingTextScrollHandler';
import { ListGroup, Card } from 'react-bootstrap';
import moment from 'moment';
import { getScrollingMessages } from '../../../../redux/slices/myGymTV/scrollingMessagesSlice';
import { useMediaQuery } from 'react-responsive';

const ScrollingText = () => {
  const navigate = useNavigate();
  const memoizedHandleScroll = useCallback(handleScroll, []);
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1023px)',
  });
  const dispatch = useDispatch();

  const navigateToDetails = (id) => {
    navigate(`/scrolling-details/${id}`);
  };

  const today = moment().startOf('day').add(4, 'hours');

  let scrollingTexts = useSelector((state) => state.scrollingText.scrollingMessages) || [];

  scrollingTexts = scrollingTexts.map(text => {
    const startDate = moment(text.StartDate);
    const endDate = moment(text.EndDate);

    const nullDate1Part = '0001-01';
    const nullDate2Part = '1900-01';

    let active = false;
    let scheduled = false;

    // Convert dates to ISO string (or any string format) for substring check
    const startDateString = startDate.format('YYYY-MM-DD');
    const endDateString = endDate.format('YYYY-MM-DD');

    // Check if either startDate or endDate contains '0001-01' or '1900-01'
    const noStartDate = startDateString.includes(nullDate1Part);
    const noEndDate = endDateString.includes(nullDate1Part) || endDateString.includes(nullDate2Part);

    // If both start and end dates contain '0001-01' or '1900-01', mark as active with no end date
    if (noStartDate && noEndDate) {
      return { ...text, active: true, endDate: false }; // Mark message as active with no end date
    }

    // Check for messages with valid start and end dates
    if (startDate.isSameOrBefore(today) && (noEndDate || endDate.isAfter(today))) {
      return { ...text, active: true };
    } else if (startDate.isAfter(today)) {
      return { ...text, scheduled: true };
    } else {
      return text; // Unmodified message
    }
  });


  const nowPlayingScrollingTexts = scrollingTexts.filter((scrollingText) => scrollingText.active === true);
  const nowPlayingScrollingTextsCount = nowPlayingScrollingTexts ? nowPlayingScrollingTexts.length : null;

  const scheduledScrollingTexts = scrollingTexts.filter((scrollingText) => scrollingText.scheduled === true);
  const scheduledScrollingTextsCount = scheduledScrollingTexts ? scheduledScrollingTexts.length : null;

  const unscheduledScrollingTexts = scrollingTexts.filter((scrollingText) => scrollingText.EndDate)

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getScrollingMessages());
    const nowPlayingHeader = document.getElementById('now-playing-header');
    const scheduledHeader = document.getElementById('scheduled-header');

    if (nowPlayingHeader) {
      nowPlayingHeader.style.display = nowPlayingScrollingTextsCount > 0 ? 'block' : 'none';
    }

    if (scheduledHeader) {
      scheduledHeader.style.display = scheduledScrollingTextsCount > 0 ? 'block' : 'none';
    }

    window.addEventListener('scroll', memoizedHandleScroll);

    return () => {
      window.removeEventListener('scroll', memoizedHandleScroll);
    };
  }, [memoizedHandleScroll, nowPlayingScrollingTextsCount, scheduledScrollingTextsCount, getScrollingMessages, dispatch]);



  return (
    <Layout>
      <div className="scrolling-text-header">
        <div className="card-header">Scrolling Text</div>
        <div
          className="card-header"
          id="now-playing-header"
          style={{ display: nowPlayingScrollingTextsCount > 0 ? 'block' : 'none' }}
        >Now Playing ({nowPlayingScrollingTextsCount})
        </div>
        <div
          className="card-header"
          id="scheduled-header"
        >Coming Soon ({scheduledScrollingTextsCount})
        </div>
        {!isMobile &&
          <button
            className="btn btn-primary"
            onClick={() => { navigate('/save-scrolling-message') }}
          >
            Add New Message
          </button>
        }
      </div>
      {scrollingTexts.length ?
        <Card className="scrolling-text border-0">
          <ListGroup
            className="scrolling-text-section"
            id="now-playing-section"
            variant="flush"
          >
            {scrollingTexts.map((scrollingText, index) => (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-center p-0"
                key={scrollingText.Sequence}
                onClick={() => navigateToDetails(scrollingText.Sequence)}
              >
                {scrollingText.active && (
                  <div

                    className="w-100"
                    className="w-100"

                    key={scrollingText.Sequence}
                    key={scrollingText.Sequence}
                  >
                    <div className="now-playing-tag d-flex justify-content-between">
                      {scrollingText.EndDate && moment(scrollingText.EndDate).year() !== 9999 ? (
                        <span>Now playing until {moment(scrollingText.EndDate).format('MM/DD/YYYY')}</span>
                      ) : (
                        <span>Now playing with no end date</span>
                      )}
                      <FontAwesomeIcon
                        className="ms-2 text-white text-end"
                        icon={faEdit}
                        onClick={() => navigateToDetails(scrollingText.Sequence)}
                      />
                    </div>
                    <div className="p-2">{scrollingText.Message}</div>
                  </div>
                )}
              </ListGroup.Item>

            ))}
          </ListGroup>


          <Card.Header
            className="text-end"
            id="coming-soon-gallery-header"
          >{`Coming Soon (${scheduledScrollingTextsCount})`}</Card.Header>
          <ListGroup
            className="scrolling-text-section"
            id="coming-soon-section"
            variant="flush"
          >
            {scheduledScrollingTexts.map((scrollingText, index) => (

              <ListGroup.Item
                className="d-flex justify-content-between align-items-center p-0"
                key={scrollingText.Sequence}
                onClick={() => navigateToDetails(scrollingText.Sequence)}
              >{scrollingText.scheduled && (
                <div
                  className="w-100"
                  key={scrollingText.Sequence}
                >
                  <div className="scheduled-tag d-flex justify-content-between">Starting {moment(scrollingText.StartDate).format('MM/DD/YYYY')}
                    <FontAwesomeIcon
                      className="ms-2 text-white text-end"
                      icon={faEdit}
                    />
                  </div>
                  <div className="p-2">{scrollingText.Message}
                  </div>

                </div>
              )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card> :

        <div className="mt-5 p-2">
          <div
            className="alert alert-secondary d-flex justify-content-between align-items-center"
            role="alert"
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={faCircleInfo}
            />
            <div className="ms-2 entertainment__action-info">You have no Scrolling Text. Tap the plus icon below to get started.</div>
          </div>
        </div>
      }

    </Layout>
  );
};

export default ScrollingText;
