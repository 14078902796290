import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { registerUser, assignRolesAndClubs } from '../../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTimes, faCircleExclamation, faPlus } from '@fortawesome/free-solid-svg-icons';

const generatePassword = (length = 6) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*+';
  return Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map(x => charset[x % charset.length])
    .join('');
};

const NewUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);
  const [clubIds, setClubIds] = useState(['']);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');

  useEffect(() => {
    const tempPassword = generatePassword();
    setPassword(tempPassword);
  }, []);

  const roleOptions = [
    { label: 'Advertisements', value: 1 },
    { label: 'Music Entertainment', value: 6 },
    { label: 'Scrolling Text', value: 7 },
    { label: 'Admin', value: 10, disabled: true },
    { label: 'Advertiser', value: 11, disabled: true },
  ];

  const hasNonAdminRoles = roles.some((role) => role !== 10 && role !== 11);

  const selectedRoleLabels = roles
    .map((role) => roleOptions.find((option) => option.value === role)?.label)
    .filter(Boolean);

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (clubIds.some((clubId) => !/^\d+$/.test(clubId))) {
      newErrors.clubIds = 'Club IDs must be numbers only';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    const roleValue = Number(value);

    setRoles((prev) =>
      checked ? [...prev, roleValue] : prev.filter((role) => role !== roleValue)
    );
  };

  const handleClubIdChange = (index, value) => {
    const updatedClubIds = [...clubIds];
    updatedClubIds[index] = value.replace(/\D/g, '');
    setClubIds(updatedClubIds);
  };

  const addClubIdField = () => {
    setClubIds([...clubIds, '']);
  };

  const removeClubIdField = (index) => {
    setClubIds((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCloseModal = () => setShowModal(false);

  const handleConfirm = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmSubmit = async () => {
    setShowConfirmation(false);

    if (!validateForm()) {
      return;
    }

    const userDetails = {
      Email: email,
      Password: password,
    };

    const response = await dispatch(registerUser(userDetails));
    if (response.payload && response.payload.UserID) {
      console.log('got this far')
      const userID = response.payload.UserID;
      const rolesString = roles.join(','); 
      const clubIdsString = clubIds.join(',');
 
      if (roles.length > 0 && clubIds.length > 0) {
        const additionalData = {
          UserID: userID,
          Roles: rolesString,
          EntityID: clubIdsString,
        };
        await dispatch(assignRolesAndClubs(additionalData));
      }

      setEmail('');
      setRoles([]);
      setClubIds(['']);
      setErrors({});
    };
  }
  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password)
      .then(() => {
        setCopyMessage('Password copied to clipboard!');
        setTimeout(() => setCopyMessage(''), 3000);
      })
      .catch(() => {
        setCopyMessage('Failed to copy password.');
      });
  };

  return (
    <>
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <form onSubmit={handleConfirm}>
            {/* Email Input */}
            <div className="form-outline mb-4">
              <input
                className="form-control form-control-lg"
                id="form1Example1"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              />
              <label
                className="form-label"
                htmlFor="form1Example1"
              >
                Email address
              </label>
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </div>

            {/* Temporary Password */}
            <div className="form-outline mb-4">
              <input
                className="form-control form-control-lg"
                hidden
                id="form1Example2"
                readOnly
                type="text"
                value={password}
              />
            </div>

            {/* Roles Section */}
            <div className="mb-4">
              <label className="form-label">Role(s)</label>
              {roleOptions.map((role) => (
                <div
                  className="form-check"
                  key={role.value}
                >
                  <input
                    className="form-check-input"
                    disabled={role.disabled}
                    id={`role-${role.value}`}
                    onChange={handleRoleChange}
                    type="checkbox"
                    value={role.value}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`role-${role.value}`}
                  >
                    {role.label}
                  </label>
                </div>
              ))}
            </div>

            {/* Club IDs Section */}
            {hasNonAdminRoles && (
              <div className="mb-4">
                <label className="form-label">Club ID(s)</label>
                {clubIds.map((clubId, index) => (
                  <div
                    className="input-group mb-2"
                    key={index}
                  >
                    <input
                      className="form-control"
                      onChange={(e) => handleClubIdChange(index, e.target.value)}
                      placeholder="Enter Club ID"
                      type="text"
                      value={clubId}
                    />
                    {index > 0 && clubId && (
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => removeClubIdField(index)}
                        type="button"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    )}
                    {index === clubIds.length - 1 && (
                      <button
                        className="btn btn-outline-secondary"
                        onClick={addClubIdField}
                        type="button"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Submit Button */}
            <div className="w-100 mt-4">
              <Button
                className="btn-lg w-100"
                type="submit"
                variant="primary"
              >
                Create Account
              </Button>
            </div>
          </form>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        onHide={() => setShowConfirmation(false)}
        show={showConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm User Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Email: {email}</p>
          <p>Roles: {selectedRoleLabels.join(', ')}</p>
          <p>
            Temporary Password: {password}
            <FontAwesomeIcon
              className="ms-3 text-secondary"
              icon={faCopy}
              onClick={handleCopyPassword}
            />
          <br />
          {copyMessage && <div className="text-success">{copyMessage}</div>}
          </p>

          <p>Club IDs: {clubIds.filter(Boolean).join(', ')}</p>
          <p>Are you sure you want to proceed with creating this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowConfirmation(false)}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal
        onHide={handleCloseModal}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              className="text-white"
              icon={faCircleExclamation}
              size="2xl"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ms-2 text-success alert alert-success">Successfully Created User!</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseModal}
            variant="secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewUser;
