import React, { useState, useEffect } from 'react';
import Layout from '../../../../layout/Layout';
import DatePicker from 'react-datepicker';
import { Card, Form, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmarkCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';
import './scrolling-text.scss';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  saveScrollingMessages,
  getScrollingMessages
} from '../../../../redux/slices/myGymTV/scrollingMessagesSlice.js';
import { v4 as uuidv4 } from 'uuid';

const CreateScrollingText = () => {
  const { id } = useParams();
  const text = useSelector((state) => {
    const scrollMessages = state.scrollingText?.scrollingMessages;
    return scrollMessages?.find((message) => message.Sequence === parseInt(id)) || null;
  });

  const [messages, setMessages] = useState([{ id: uuidv4(), message: '', startDate: null, endDate: null }]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MESSAGE_MAX_LENGTH = 255;

  useEffect(() => {
    if (text) {
      setMessages([{
        id: text.Sequence,
        message: text.Message,
        startDate: text.StartDate ? new Date(text.StartDate) : null,
        endDate: text.EndDate ? new Date(text.EndDate) : null
      }]);
    }
  }, [text, id]);

  const handleAddMessage = () => {
    setMessages([...messages, { id: uuidv4(), message: '', startDate: null, endDate: null }]);
  }

  const handleRemoveMessage = (id) => {
    setMessages(messages.filter(message => message.id !== id));
  }

  const handleInputChange = (id, field, value) => {
    setMessages(messages.map(message => message.id === id ? { ...message, [field]: value } : message));
  };

  const handleAddScrollingText = async () => {
    const scrollMessages = messages.map((message) => ({
      Message: message.message,
      StartDate: message.startDate ? message.startDate.toISOString() : null,
      EndDate: message.endDate ? message.endDate.toISOString() : null,
      Sequence: text ? message.id : null,
      active: true,
    }));

    try {
      const saveResult = await dispatch(saveScrollingMessages(scrollMessages));
      unwrapResult(saveResult);

      const fetchResult = await dispatch(getScrollingMessages());
      unwrapResult(fetchResult);

      navigate('/scrolling-text');
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <Layout>
      <Card>
        <Card.Header as="h5">{text ? 'Edit' : 'Create'} Scrolling Text</Card.Header>
        <Card.Body>
          {messages.map(({ id, message, startDate, endDate }, index) => (
            <div
className="border py-3 my-3 rounded-3"
key={id}
            >
              <div className="input-group px-1 py-2">
                <textarea
                  className="form-control"
                  onChange={(e) => handleInputChange(id, 'message', e.target.value)}
                  rows="5"
                  value={message}
                />
                <span className="input-group-text">{message.length}/{MESSAGE_MAX_LENGTH}</span>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Date Range</Form.Label>
                <div className="d-flex">
                  <DatePicker
                    className="form-control"
                    onChange={(date) => handleInputChange(id, 'startDate', date)}
                    placeholderText="Start Date"
                    selected={startDate && startDate.getFullYear() === 1753 ? null : startDate}
                  />
                  <DatePicker
                    className="form-control"
                    onChange={(date) => handleInputChange(id, 'endDate', date)}
                    placeholderText="End Date"
                    selected={endDate && endDate.getFullYear() === 9999 ? null : endDate}
                  />
                </div>
              </Form.Group>

              {index !== 0 && (
                <Button
onClick={() => handleRemoveMessage(id)}
variant="danger"
                >Remove</Button>
              )}
            </div>
          ))}

          <Button
onClick={handleAddMessage}
variant="primary"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Message
          </Button>

          <Button
disabled={!messages.some(msg => msg.message)}
onClick={handleAddScrollingText}
variant="success"
          >
            Save
          </Button>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default CreateScrollingText;
