import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faImages, faMessage, faUser, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlusIcon from './PlusIcon';
import './mobile-nav.scss';

const TapBar = () => {
  const user = useSelector((state) => state.user);
  const adAccount = user.initialLoginData?.Account;
  const roles = user.user?.RoleIds || [];
  const location = useLocation();
  const navigate = useNavigate();
  const validPaths = ['/digital-signage', '/scrolling-text'];

  return (
    <div className="tapbar mobile wrapper">
      <div className="navbar">
        {roles.includes(6) && (
          <FontAwesomeIcon
            className="content-overview"
            icon={faMusic}
            onClick={() => navigate('/entertainment')}
          />
        )}
        {roles.includes(1) && (
          <FontAwesomeIcon
            className="account-settings"
            icon={faImages}
            onClick={() => navigate('/digital-signage')}
          />
        )}
        {roles.includes(7) && (
          <div
            className="icon-wrapper"
            onClick={() => navigate('/scrolling-text')}
          >
            <FontAwesomeIcon
              className="fa-icon account-settings"
              icon={faMessage}
            />
            <span className="icon-text">T</span>
          </div>
        )}
        {adAccount && (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faUser}
            onClick={() => navigate('/ad-account')}
          />
        )}
        {adAccount ? (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faHeadset}
            onClick={() => {
              window.location.href = 'https://chat.socialintents.com/c/mygymtv';
            }}
          />
        ) : (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faHeadset}
            onClick={() => {
              window.location.href = 'https://chat.socialintents.com/c/clubcom';
            }}
          />
        )}

        {validPaths.includes(location.pathname) && (
          <>
            <PlusIcon />
            <div className="circleBackground" />
          </>
        )}
      </div>
    </div>
  );
};

export default TapBar;
