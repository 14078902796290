import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtDecode from 'jwt-decode';


export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (arg, { dispatch, getState }) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/UserData`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await dispatch(getInitialLoginData(response.data));
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const getInitialLoginData = createAsyncThunk(
  'user/getInitialLoginData',
  async (data, { getState }) => {
    const token = localStorage.getItem('token');
    try {
      if (data.UserID && data.RoleIds.includes(11)) {
        const advertiser = !data.RoleIds.includes(10);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CRM/GetAgreementsByUserID?userId=${data.UserID}&advertiser=${advertiser}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } else {
        if (data.UserID) {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/GetInitialLoginData?userId=${data.UserID}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        }
      }

    } catch (error) {
      console.error('error', error);
    }
  }
);


export const setSelectedVenue = createAction('user/setSelectedVenue');

const storedSelectedVenue = JSON.parse(localStorage.getItem('selectedVenue'));

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (userDetails, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/login`,
        userDetails
      );

      // Assuming you want to set a success message explicitly
      const successMessage = 'Login successful'; // Define your success message

      const decodedToken = jwtDecode(response.data.Token);
      localStorage.setItem('token', response.data.Token);

      dispatch(getUserData());

      // Return a custom object with the message
      return { message: successMessage };
    } catch (error) {
      console.error('error', error);
      return rejectWithValue(error.response?.data?.Message || 'Login failed.');
    }
  }
);


export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (userDetails, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/User/Create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
      });

      if (!response.ok) {
        const errorData = await response.text();
        const errorMessageObject = errorData && JSON.parse(errorData);
        const message = errorMessageObject && errorMessageObject.Message;
        console.error('Registration failed:', message);
        return rejectWithValue(message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Registration error:', error);
      return rejectWithValue(error.toString());
    }
  }
);

export const assignRolesAndClubs = createAsyncThunk(
  'user/assignRolesAndClubs',
  async (additionalData, { rejectWithValue }) => {
    const { UserID, Roles, EntityID } = additionalData;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/User/AssignRolesAndClubs`,
        { UserID, Roles, EntityID } // Correct property names
      );

      if (response.status !== 200) {
        return rejectWithValue('Failed to assign roles and clubs');
      }

      return response.data; // Return the result if needed
    } catch (error) {
      console.error('Error assigning roles and clubs:', error);
      return rejectWithValue(error.toString());
    }
  }
);



export const checkPasswordResetRequested = createAsyncThunk(
  'user/checkPasswordResetRequested',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/checkPasswordResetRequested?email=${email}`);
      return response.data;
    } catch (error) {
      console.error('Error checking password reset requested:', error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPasswordForNewAdvertiser = createAsyncThunk(
  'user/createPasswordForNewAdvertiser',
  async ({ email, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/User/CreatePasswordForNewAdvertiser?email=${encodeURIComponent(email)}`,
        { NewPassword: newPassword }
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'user/requestPasswordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/User/RequestPasswordReset`, { email });
      return response.data.Message;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateResetToken = createAsyncThunk(
  'user/validateResetToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/User/ValidateToken`, { token });
      if (response.data.isValid) {
        return { isValidToken: true, email: response.data.email };
      } else {
        return rejectWithValue('Invalid or expired token.');
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : 'An error occurred');
    }
  }
);




export const logoutUser = createAsyncThunk('user/logoutUser', async (arg, { dispatch }) => {
  localStorage.removeItem('token');
  localStorage.removeItem('selectedVenue');
  return null;
});


// User slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    initialLoginData: [],
    selectedVenue: storedSelectedVenue,
    status: 'idle',
    rror: null,
    isValidToken: null,
    email: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(getInitialLoginData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.initialLoginData = action.payload;
      })
      .addCase(setSelectedVenue, (state, action) => {
        state.selectedVenue = action.payload;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.loginStatus = action.payload.message;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.initialLoginData = [];
        state.selectedVenue = null;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(checkPasswordResetRequested.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkPasswordResetRequested.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(checkPasswordResetRequested.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createPasswordForNewAdvertiser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPasswordForNewAdvertiser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createPasswordForNewAdvertiser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(requestPasswordReset.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.passwordResetStatus = action.payload;
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.passwordResetStatus = action.payload;
      })
      .addCase(validateResetToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateResetToken.fulfilled, (state, action) => {
        state.isValidToken = action.payload.isValidToken;
        state.email = action.payload.email;
        state.isLoading = false;
      })
      .addCase(validateResetToken.rejected, (state, action) => {
        state.isValidToken = false;
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
