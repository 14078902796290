export const determineRedirectPath = () => {
  const currentPath = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username');
  const token = urlParams.get('token');
  if (currentPath.includes('/login') && username) {
    return currentPath;
  }
  else if (username) {
    return `/login?username=${username}`;
  }
  else if (currentPath.includes('/reset-password')) {
    return currentPath;
  }
  else if (token) {
    return currentPath;
  }
  else {
    return '/login';
  }
};
