import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout/Layout';
import { Button, Form, Card, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
  uploadImage,
  uploadVideo,
  editContent
} from '../../../../redux/slices/myGymTV/signageSlice';

const UploadSignage = () => {
  const navigate = useNavigate();
  const contentID = useParams();

  // Fetch state and dispatch
  const content = useSelector((state) => {
    const signages = state.signage?.marketingOrderContent;
    if (signages) {
      const foundContent = signages.find((content) => {
        return content.ContentID === parseInt(contentID.id);
      });
      return foundContent ? foundContent : null;
    }
    return null;
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [contentTypeID, setContentTypeID] = useState(null);
  const [displaySeconds, setDisplaySeconds] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const dispatch = useDispatch();
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const user = useSelector((state) => state.user) || [];
  const signage =
    useSelector((state) => state.signage.marketingOrderContent) || [];
  const [editSignage, setEditSignage] = useState([]);

  useEffect(() => {
    if (content) {
      setTitle(content.ContentName);
      setStartDate(content.StartDate ? new Date(content.StartDate) : null);
      setEndDate(content.EndDate ? new Date(content.EndDate) : null);
      setEditSignage([
        {
          contentID: content.ContentID,
          title: content.ContentName,
          startDate: new Date(content.StartDate),
          endDate: new Date(content.EndDate)
        }
      ]);

      // Set mediaType based on existing content
      if (content.ContentType === 'I') {
        setMediaType(1); // Image
        setContentTypeID(3);
        setDisplaySeconds(15);
      } else if (content.ContentType === 'V') {
        setMediaType(2); // Video
        setContentTypeID(2);
        setDisplaySeconds(content.DisplaySeconds);
      }
    }
  }, [content, contentID]);

  function toSafeInteger(num) {
    if (Number.isSafeInteger(num)) {
      return num;
    }
    return Math.min(
      Math.max(Math.round(num), Number.MIN_SAFE_INTEGER),
      Number.MAX_SAFE_INTEGER
    );
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedMediaTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'video/mp4',
      'video/webm',
      'video/ogg',
      'video/mpeg',
      'video/quicktime'
    ];

    if (!allowedMediaTypes.includes(file.type)) {
      alert(
        'Please upload a valid image or video format: PNG, JPG, JPEG, GIF, MP4, WebM, Ogg, MPEG, MOV.'
      );
      event.target.value = '';
    } else {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        const isVideo = file.type.startsWith('video');

        if (!isVideo) {
          setMediaType(1);
          setDisplaySeconds(15);
          setContentTypeID(3);
          setPreviewImage(reader.result);
          const image = new Image();
          image.onload = () => {
            setHeight(image.height);
            setWidth(image.width);
          };
          image.src = reader.result;
        }

        if (isVideo) {
          setContentTypeID(2);
          const video = document.createElement('video');
          video.preload = 'metadata';

          setMediaType(2);

          video.onloadedmetadata = () => {
            const durationInSeconds = toSafeInteger(video.duration);

            if (durationInSeconds > 60) {
              alert('Video duration cannot be longer than 1 minute.');
              // Reset the form
              setSelectedFile(null);
              setVideoDuration(null);
              setContentTypeID(null);
              setDisplaySeconds(null);
              setMediaType(null);
              setPreviewImage(null);
              setHeight(null);
              setWidth(null);
              event.target.value = ''; // Clear file input
              return;
            }

            setDisplaySeconds(durationInSeconds);
            // Capture a frame to create a thumbnail
            video.currentTime = video.duration / 3; // capturing at 1/3 duration
          };

          video.onseeked = () => {
            const canvas = document.createElement('canvas');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnail = canvas.toDataURL('image/jpeg');
            setPreviewImage(thumbnail); // setting the thumbnail as preview
            setHeight(canvas.height);
            setWidth(canvas.width);
          };

          video.src = URL.createObjectURL(file);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handlestartDateChange = (date) => {
    setStartDate(date);
  };

  const handleendDateChange = (date) => {
    setEndDate(date);
  };

  let newRecordXml = {};

  const handleUpload = async () => {
    setShowModal(true);

    // Check if this is a new signage upload or an edit
    if (!editSignage.length && !selectedFile) {
      alert('Please select a file before uploading.');
      setShowModal(false); // Hide modal if there's no file in upload mode
      return;
    }

    const start = startDate ? startDate.toISOString() : null;
    const end = endDate ? endDate.toISOString() : null;
    const formData = new FormData();

    // For edit mode, selectedFile might be null, but you don't want to prevent saving changes to dates
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    formData.append('name', title);
    formData.append('user', user.user.Email);
    formData.append('contentTypeID', contentTypeID);
    formData.append('venueID', user.selectedVenue.EntityID);
    formData.append('orderID', signage[0].orderID);

    // Distinguish between new upload and edit mode
    if (!editSignage.length) {
      var digitalSignageImageModel = {
        createdBy: user.user.Email,
        name: title,
        type: mediaType, // either image or video
        fileName: selectedFile?.name || '', // Use empty string if no file selected (edit mode)
        fileSize: selectedFile?.size || 0,
        height: height,
        width: width,
        venueID: user.selectedVenue.EntityID,
        startDate: start,
        endDate: end,
        IsActive: true,
        displaySeconds: displaySeconds
      };

      newRecordXml = {
        ContentID: 'contentID_placeholder',
        isActive: true,
        startDate: start,
        endDate: end,
        displaySeconds: displaySeconds,
        contentTypeID: contentTypeID
      };
    } else {
      // Handle edit mode, no need for file upload, just updating metadata
      newRecordXml = {
        ContentID: editSignage[0].contentID,
        isActive: true,
        startDate: start,
        endDate: end,
        displaySeconds: displaySeconds || 15,
        contentTypeID: contentTypeID || 3 // Assume image by default
      };
    }

    let existingRecordsXml = signage.map((record) => ({
      ContentID: record.ContentID,
      isActive: record.IsActive,
      startDate: record.StartDate,
      endDate: record.EndDate,
      displaySeconds: record.DisplaySeconds,
      contentTypeID: record.ContentType === 'I' ? 3 : 2
    }));

    let recordExists = false;
    existingRecordsXml = existingRecordsXml.map((record) => {
      if (record.ContentID === newRecordXml.ContentID) {
        recordExists = true;
        return {
          ...record,
          startDate: newRecordXml.startDate,
          endDate: newRecordXml.endDate
        };
      }
      return record;
    });

    if (!recordExists) {
      existingRecordsXml.push(newRecordXml);
    }

    // Convert to JSON string to pass as FormData
    formData.append('digitalSignageImageModel', JSON.stringify(newRecordXml));

    try {
      let action;
      if (mediaType === 1 && selectedFile) {
        // Image upload (if file exists)
        action = await dispatch(
          uploadImage({
            formData,
            orderID: signage[0].orderID,
            xmlContent: existingRecordsXml
          })
        );
      } else if (mediaType === 2 && selectedFile) {
        // Video upload (if file exists)
        action = await dispatch(
          uploadVideo({
            formData,
            orderID: signage[0].orderID,
            xmlContent: existingRecordsXml
          })
        );
      } else {
        // Handle edit without file upload
        action = await dispatch(
          editContent({
            formData,
            orderID: signage[0].orderID,
            xmlContent: existingRecordsXml
          })
        );
      }

      if (action.type.endsWith('fulfilled')) {
        // Reset form after successful upload
        setSelectedFile(null);
        setWidth(null);
        setHeight(null);
        setPreviewImage(null);
        setTitle('');
        setStartDate(null);
        setEndDate(null);
        navigate('/digital-signage');
      } else {
        console.error('Error uploading signage:', action);
      }
    } catch (error) {
      console.error('Failed to upload signage:', error);
    } finally {
      setShowModal(false); // Hide the modal when upload completes
    }
  };

  const isFormValid = !editSignage.length ? selectedFile && title : title;

  return (
    <Layout>
      <Card>
        <Card.Header as="h5">
          {editSignage.length ? 'Edit Signage' : 'Upload Signage'}
        </Card.Header>
        <Card.Body>
          <Form className="upload">
            {!editSignage.length ? (
              <>
                <Form.Group
className="mb-3"
controlId="formPreviewImage"
                >
                  {previewImage && (
                    <div className="text-center">
                      <img
                        alt="Selected Signage"
                        className="rounded"
                        src={previewImage}
                      />
                    </div>
                  )}
                </Form.Group>
                <Form.Group
className="mb-3"
controlId="formFileUpload"
                >
                  <Form.Control
                    accept=".png, .jpg, .jpeg, .gif, .mp4, .webm, .ogg, .mpeg, .mov"
                    name="file"
                    onChange={handleFileSelect}
                    type="file"
                  />
                </Form.Group>
              </>
            ) : null}

            <Form.Group
className="mb-3"
controlId="formBasicInput"
            >
              <Form.Label>Title</Form.Label>
              <Form.Control
                disabled={editSignage.length}
                onChange={handleTitleChange}
                placeholder="Enter Title"
                type="text"
                value={title}
              />
            </Form.Group>
            <Form.Group
className="mb-3"
controlId="formBasicInput"
            >
              <Form.Label>Date Range</Form.Label>
              <Button className="d-flex rounded mb-2 w-100">
                <FontAwesomeIcon
                  className="account-settings"
                  icon={faCalendar}
                  onClick={() => navigate('/digital-signage')}
                />
                <DatePicker
                  className="btn btn-primary w-50"
                  onChange={handlestartDateChange}
                  placeholderText="Start"
                  selected={
                    startDate && startDate.getFullYear() === 1753
                      ? null
                      : startDate
                  }
                />
              </Button>
              <Button className="d-flex rounded w-100">
                <FontAwesomeIcon
                  className="account-settings"
                  icon={faCalendar}
                  onClick={() => navigate('/digital-signage')}
                />
                <DatePicker
                  className="btn btn-primary"
                  onChange={handleendDateChange}
                  placeholderText="End"
                  selected={
                    endDate && endDate.getFullYear() === 9999 ? null : endDate
                  }
                />
              </Button>
            </Form.Group>
            <Button
disabled={!isFormValid}
onClick={handleUpload}
            >
              {editSignage.length ? 'Save' : 'Upload'}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Modal with spinner to show during upload */}
      <Modal
backdrop="static"
centered
keyboard={false}
show={showModal}
      >
        <Modal.Body className="text-center">
          <Spinner
animation="border"
role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="mt-3">File uploading. Please wait...</p>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default UploadSignage;
