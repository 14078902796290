import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import GymLogo from '../../images/gym_logo.png';
import GymImage from '../../images/Mygymtv-login.jpg';
import { loginUser } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import '../../App.css';

const DefaultLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering] = useState(false);
  const dispatch = useDispatch();
  const { status, loginStatus, error } = useSelector(state => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email && password) {
      try {
        await dispatch(loginUser({ Email: email, Password: password }));
      } catch (error) {
        console.log(error);
      }
    }

    setEmail('');
    setPassword('');
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-6 d-none d-md-block text-center img-container">
            <img
              alt="Gym"
              className="gym-image w-100"
              src={GymImage}
            />
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4 mt-2 text-center form-container">
            <img
              alt="Logo"
              className="img-fluid my-5 w-75 text-center mx-auto"
              src={GymLogo}
            />
            <form
className="text-start"
onSubmit={handleSubmit}
            >
              <div className="form-outline mb-4">
                <input
                  autoComplete="email"
                  className="form-control form-control-lg"
                  id="form1Example1"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                />
                <label
className="form-label"
htmlFor="form1Example1"
                >
                  Email address
                </label>
              </div>
              <div className="form-outline mb-4">
                <input
                  autoComplete="current-password"
                  className="form-control form-control-lg"
                  id="form1Example2"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                />
                <label
className="form-label"
htmlFor="form1Example2"
                >
                  Password
                </label>
              </div>
              <div className="w-100">
                <Button
                  className="btn-lg w-100"
                  type="submit"
                  variant="primary"
                >
                  {isRegistering ? 'Request an Account' : 'Sign in'}
                </Button>
              </div>
            </form>
            {status === 'failed' && <div className="alert alert-danger my-4">{error}</div>}
            {status === 'success' && <div className="alert alert-success my-4">{loginStatus}</div>}
            <div className="mt-4">
              <a
className="text-decoration-none"
href="/reset-password"
              >
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DefaultLogin;
