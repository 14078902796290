import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCurrentChannel } from '../../../helpers/transformations';

export const fetchEntertainmentData = createAsyncThunk(
  'entertainment/fetchEntertainmentData',
  async (arg, { dispatch, getState }) => {
    await dispatch(getEntertainmentChannels()).then(() => {
      return dispatch(getChannelPreferences());
    });
  }
);

export const getEntertainmentChannels = createAsyncThunk(
  'entertainment/getEntertainmentChannels',
  async (arg, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const params = {
      entityTypeID: 5,
      serverID: selectedVenue.ServerInstallId,
      zoneName: selectedVenue.ZoneName,
      ZoneTypeID: selectedVenue.ServerZoneTypeId
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/GetChannels`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const channels = response.data;
      dispatch(setChannels(channels));
      return response.data;

    } catch (error) {
      console.error('error', error);
    }
  }
);

export const getChannelPreferences = createAsyncThunk(
  'entertainment/getChannelPreferences',
  async (arg, { getState, dispatch }) => {

    const { user } = getState();
    const { selectedVenue } = user;
    const { channels } = getState().entertainment;
    const token = localStorage.getItem('token');
    const params = {
      entityTypeID: 5,
      serverID: selectedVenue.ServerInstallId,
      zoneName: selectedVenue.ZoneName,
      ZoneTypeID: selectedVenue.ServerZoneTypeId
    };
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/GetPreferences`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const preferences = response.data;
      localStorage.setItem('preferences', JSON.stringify(preferences));
      const currentChannel = getCurrentChannel(preferences);
      const matchingChannel = channels.find(channel => channel.ChannelID === currentChannel.ChannelID);
      if (matchingChannel) {
        currentChannel.WebDescription = matchingChannel.WebDescription;
      }
      dispatch(setCurrentChannel(currentChannel));
      dispatch(setSelectedChannel([]));
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateAllChannels = createAsyncThunk(
  'entertainment/updateAllChannels',
  async (selectedChannel, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;
    const newChannelId = selectedChannel.ChannelID;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/UpdateAllChannels`,
        {
          serverInstallId,
          zoneId,
          newChannelId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateChannelPrefsByDayOrTime = createAsyncThunk(
  'entertainment/updateChannelPrefs',
  async ({ selectedChannel, selectedDay, selectedHour }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;
    const NewChannelId = selectedChannel;
    const newPrefDay = selectedDay;
    let newPrefTime;
    if (selectedHour.label) {
      console.log('shl',selectedHour.label)
      newPrefTime = parseInt(selectedHour.label) }
    else if (selectedHour.data) {
      newPrefTime = selectedHour.data
    } else { console.log('sh', selectedHour); newPrefTime = selectedHour; }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/UpdatePrefs`,
        {
          serverInstallId,
          zoneId,
          NewChannelId,
          newPrefDay,
          newPrefTime
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateChannelPrefsAllDay = createAsyncThunk(
  'entertainment/updateChannelPrefsAllDay',
  async ({ selectedChannel, selectedDay }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;
    const newChannelId = selectedChannel;
    const newPrefDay = selectedDay;
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/UpdatePrefsAllDay`,
        {
          serverInstallId,
          zoneId,
          newChannelId,
          newPrefDay
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateChannelCopyToMoreDays = createAsyncThunk(
  'entertainment/copyChannelToMoreDays',
  async ({ selectedChannel, selectedDay, selectedHour, selectedDays }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;
    const newChannelId = selectedChannel;
    const newPrefDay = selectedDay;
    const newPrefTime = parseInt(selectedHour.label);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/CopyToMoreDays`,
        {
          serverInstallId,
          zoneId,
          newChannelId,
          newPrefDay,
          newPrefTime,
          selectedDays
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateChannelCopyToMoreDaysAllDay = createAsyncThunk(
  'entertainment/copyChannelToMoreDaysAllDay',
  async ({ selectedChannel, selectedDays }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;
    const newChannelId = selectedChannel;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/CopyToMoreDaysAllDay`,
        {
          serverInstallId,
          zoneId,
          newChannelId,
          selectedDays
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateChannelReplaceAll = createAsyncThunk(
  'entertainment/updateChannelReplaceAll',
  async ({ newChannelId, currentChannelId }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/UpdateChannelAll`,
        {
          serverInstallId,
          zoneId,
          newChannelId,
          currentChannelId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const updateDaySchedule = createAsyncThunk(
  'entertainment/copyDayScheduleToMoreDays',
  async ({ selectedDays, daySchedules }, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const serverInstallId = selectedVenue.ServerInstallId;
    const zoneId = selectedVenue.ZoneId;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Entertainment/CopyDayToDays`,
        {
          serverInstallId,
          zoneId,
          selectedDays,
          daySchedules
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      dispatch(getChannelPreferences());
      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

const entertainmentSlice = createSlice({
  name: 'entertainment',
  initialState: {
    channels: [],
    preferences: JSON.parse(localStorage.getItem('preferences')) || [],
    selectedDay: {},
    selectedHour: null,
    selectedHourRange: {},
    selectedDayPart: null,
    selectedChannel: JSON.parse(localStorage.getItem('selectedChannel')) || null,
    currentChannel: JSON.parse(localStorage.getItem('currentChannel')) || null,
    editingChannel: JSON.parse(localStorage.getItem('editingChannel')) || null,
    status: 'idle', error: null
  },
  reducers: {
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    setSelectedHour: (state, action) => {
      state.selectedHour = action.payload;
    },
    setSelectedHourRange: (state, action) => {
      state.selectedHourRange = action.payload;
    },
    setSelectedDayPart: (state, action) => {
      state.selectedDayPart = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
      localStorage.setItem('selectedChannel', JSON.stringify(action.payload));
    },
    setCurrentChannel: (state, action) => {
      state.currentChannel = action.payload;
    },
    setChannels: (state, action) => {
      state.channels = action.payload;
      localStorage.setItem('channels', JSON.stringify(action.payload));
    },
    setEditingChannel: (state, action) => {
      state.editingChannel = action.payload;
      localStorage.setItem('editingChannel', JSON.stringify(action.payload));
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getEntertainmentChannels.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEntertainmentChannels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.channels = action.payload;
      })
      .addCase(getEntertainmentChannels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getChannelPreferences.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getChannelPreferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.preferences = action.payload;
      })
      .addCase(getChannelPreferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateChannelPrefsByDayOrTime.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChannelPrefsByDayOrTime.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateChannelPrefsByDayOrTime.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateAllChannels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAllChannels.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateAllChannels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateChannelCopyToMoreDaysAllDay.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChannelCopyToMoreDaysAllDay.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateChannelCopyToMoreDaysAllDay.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateChannelReplaceAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChannelReplaceAll.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateChannelReplaceAll.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateDaySchedule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDaySchedule.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateDaySchedule.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { setChannels, setSelectedDay, setSelectedHour, setSelectedHourRange, setSelectedDayPart, setSelectedChannel, setCurrentChannel, setEditingChannel } = entertainmentSlice.actions;
export default entertainmentSlice.reducer;
