import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronLeft, faEdit, faFloppyDisk, faCancel } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from 'react-spring';
import { useNavigate, useLocation } from 'react-router-dom';

const iconSets = {
  '/signage-details': [
    { icon: faChevronLeft, link: '/digital-signage' },
    { icon: faEdit, link: '' },
  ],
  '/upload-signage': [
    { icon: faFloppyDisk, link: '' },
    { icon: faCancel, link: '/digital-signage' },
  ],
  '/digital-signage': [{ icon: faPlus, link: '/upload-signage' }],
};

const PlusIcon = () => {
  const [showIcons, setShowIcons] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const currentIconSet = iconSets[path] || [];
  const circleStyle = useSpring({
    height: showIcons ? `${currentIconSet.length * 60}px` : '65px',
    config: { mass: 1, tension: 500, friction: 18 },
  });

  const handlePlusIconClick = () => {
    if (path === '/digital-signage') {
      navigate('/upload-signage');
    } else if (path.includes('/signage-details')) {
      navigate('/digital-signage');
    } else if (path.includes('/scrolling-text')) {
      navigate('/save-scrolling-message');
    } else {
      setShowIcons((prev) => !prev);
    }
  };

  return (
    <animated.div
className="circle"
style={circleStyle}
    >
      {showIcons ? (
        currentIconSet.map((item, index) => (
          <FontAwesomeIcon
            className="content-management"
            icon={item.icon}
            key={index}
            onClick={() => navigate(item.link)}
          />
        ))
      ) : (
        <FontAwesomeIcon
          className="content-management"
          icon={faPlus}
          onClick={handlePlusIconClick}
        />
      )}
    </animated.div>
  );
};

export default PlusIcon;
